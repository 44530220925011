import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useStateContext } from "./contexts/ContextProvider";
import YouTube from "react-youtube";
import WebToko from "./image/Portfolio/Web_Toko.png";
import WebGudang from "./image/Portfolio/Web_Gudang.png";
import WebAkuntansi from "./image/Portfolio/Web_Akuntansi.png";
import WebGadai from "./image/Portfolio/Web_Gadai.png";
import WebKredit from "./image/Portfolio/Web_Kredit.png";
import CTA2 from "./image/CallToAction/Panggil_Programmer_Ivan_CTA_2.png";

const Portfolio = () => {
  const { screenSize } = useStateContext();
  const [isOpenYoutube, setIsOpenYoutube] = useState(false);
  const [videoActive, setVideoActive] = useState("");
  const [videoId, setVideoId] = useState("");
  const opts = {
    height: screenSize >= 930 ? "390" : "200",
    width: screenSize >= 930 ? "640" : "300",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: screenSize >= 930 ? "0px" : "20px",
      }}
    >
      <div
        className="text-center"
        style={{
          border: "1px solid orange",
          width: screenSize >= 930 ? "900px" : `${screenSize}px`,
          background: `linear-gradient(#fa9d6e, #eae41f)`,
        }}
      >
        <h2
          style={{
            color: "red",
            fontWeight: "800",
            marginTop: "20px",
            textShadow: "2px 2px 2px white",
          }}
        >
          MAU LIHAT PORTFOLIO?
        </h2>
        {!isOpenYoutube ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: screenSize >= 930 ? "800px" : `${screenSize}px`,
                  flexDirection: screenSize >= 930 ? "row" : "column",
                }}
              >
                <div>
                  <img
                    src={WebToko}
                    alt="WebToko"
                    width={300}
                    onClick={() => {
                      setIsOpenYoutube(true);
                      setVideoActive("1");
                      setVideoId("hlSvfvZ3n64");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <p style={{ color: "red", fontWeight: "800" }}>WEB TOKO</p>
                </div>
                <div>
                  <img
                    src={WebGudang}
                    alt="WebGudang"
                    width={300}
                    onClick={() => {
                      setIsOpenYoutube(true);
                      setVideoActive("2");
                      setVideoId("qdh0N0TNVQ4");
                    }}
                    style={{
                      cursor: "pointer",
                      marginTop: screenSize >= 930 ? "0px" : "20px",
                    }}
                  />
                  <p style={{ color: "red", fontWeight: "800" }}>WEB GUDANG</p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: screenSize >= 930 ? "800px" : `${screenSize}px`,
                  flexDirection: screenSize >= 930 ? "row" : "column",
                }}
              >
                <div>
                  <img
                    src={WebAkuntansi}
                    alt="WebAkuntansi"
                    width={300}
                    onClick={() => {
                      setIsOpenYoutube(true);
                      setVideoActive("3");
                      setVideoId("43cuWZpf6Og");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <p style={{ color: "red", fontWeight: "800" }}>
                    WEB AKUNTANSI
                  </p>
                </div>
                <div>
                  <img
                    src={WebGadai}
                    alt="WebGadai"
                    width={300}
                    onClick={() => {
                      setIsOpenYoutube(true);
                      setVideoActive("4");
                      setVideoId("fL0u6PeBa_I");
                    }}
                    style={{
                      cursor: "pointer",
                      marginTop: screenSize >= 930 ? "0px" : "20px",
                    }}
                  />
                  <p style={{ color: "red", fontWeight: "800" }}>WEB GADAI</p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: screenSize >= 930 ? "800px" : `${screenSize}px`,
                  flexDirection: screenSize >= 930 ? "row" : "column",
                }}
              >
                <div>
                  <img
                    src={WebKredit}
                    alt="WebKredit"
                    width={300}
                    onClick={() => {
                      setIsOpenYoutube(true);
                      setVideoActive("5");
                      setVideoId("pysF4hHG7pc");
                    }}
                    style={{
                      cursor: "pointer",
                      marginTop: screenSize >= 930 ? "0px" : "20px",
                    }}
                  />
                  <p style={{ color: "red", fontWeight: "800" }}>WEB KREDIT</p>
                </div>
                <div></div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: screenSize >= 930 ? "row" : "column",
              }}
            >
              <YouTube videoId={videoId} opts={opts} />
              <div style={{ border: "3px solid blue", padding: "10px" }}>
                <div>
                  <p
                    style={{
                      color: "red",
                      fontWeight: "700",
                      fontSize: "15px",
                    }}
                  >
                    MAU LIHAT PORTFOLIO?
                  </p>
                </div>
                {videoActive !== "1" && (
                  <div>
                    <img
                      src={WebToko}
                      alt="WebToko"
                      width={150}
                      onClick={() => {
                        setIsOpenYoutube(true);
                        setVideoActive("1");
                        setVideoId("hlSvfvZ3n64");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "800",
                        fontSize: "10px",
                      }}
                    >
                      WEB TOKO
                    </p>
                  </div>
                )}
                {videoActive !== "2" && (
                  <div>
                    <img
                      src={WebGudang}
                      alt="WebGudang"
                      width={150}
                      onClick={() => {
                        setIsOpenYoutube(true);
                        setVideoActive("2");
                        setVideoId("qdh0N0TNVQ4");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "800",
                        fontSize: "10px",
                      }}
                    >
                      WEB GUDANG
                    </p>
                  </div>
                )}
                {videoActive !== "3" && (
                  <div>
                    <img
                      src={WebAkuntansi}
                      alt="WebAkuntansi"
                      width={150}
                      onClick={() => {
                        setIsOpenYoutube(true);
                        setVideoActive("3");
                        setVideoId("43cuWZpf6Og");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "800",
                        fontSize: "10px",
                      }}
                    >
                      WEB GUDANG
                    </p>
                  </div>
                )}
                {videoActive !== "4" && (
                  <div>
                    <img
                      src={WebGadai}
                      alt="WebGadai"
                      width={150}
                      onClick={() => {
                        setIsOpenYoutube(true);
                        setVideoActive("4");
                        setVideoId("fL0u6PeBa_I");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "800",
                        fontSize: "10px",
                      }}
                    >
                      WEB GADAI
                    </p>
                  </div>
                )}
                {videoActive !== "5" && (
                  <div>
                    <img
                      src={WebKredit}
                      alt="WebKredit"
                      width={150}
                      onClick={() => {
                        setIsOpenYoutube(true);
                        setVideoActive("5");
                        setVideoId("pysF4hHG7pc");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "800",
                        fontSize: "10px",
                      }}
                    >
                      WEB KREDIT
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            <a href="https://api.whatsapp.com/send/?phone=%2B62895421397226&text&type=phone_number&app_absent=0">
              <img
                src={CTA2}
                alt="CTA2"
                style={{
                  width: screenSize >= 930 ? "500px" : "300px",
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
