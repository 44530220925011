import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Footer = () => (
  <footer className="text-center p-3">
    <p>&copy; 2024 Ivan Kristiawan. All rights reserved.</p>
  </footer>
);

export default Footer;
