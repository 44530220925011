import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useStateContext } from "./contexts/ContextProvider";
import KontakImage from "./image/Kontak/Panggil_Programmer_Ivan_Kontak.png";
import KontakImageSmallScreen from "./image/Kontak/Panggil_Programmer_Ivan_Kontak_Small_Screen.png";

const Kontak = () => {
  const { screenSize } = useStateContext();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "40px",
      }}
    >
      {screenSize >= 930 ? (
        <img src={KontakImage} alt="KontakImage" width={900} />
      ) : (
        <>
          <img src={KontakImageSmallScreen} alt="banner2" width={300} />
        </>
      )}
    </div>
  );
};

export default Kontak;
