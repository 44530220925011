import React, { useContext, useState, useEffect } from "react";
import { useStateContext } from "./contexts/ContextProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import Banner from "./Banner";
import PitchingSection from "./PitchingSection";
import CallToAction1 from "./CallToAction1";
import Portfolio from "./Portfolio";
import Kontak from "./Kontak";
import CallToAction3 from "./CallToAction3";
import Footer from "./Footer";

const App = () => {
  const { screenSize, setScreenSize } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ backgroundColor: "#eae41f", paddingTop: "50px" }}>
      <Banner />
      <PitchingSection />
      <CallToAction1 />
      <Portfolio />
      <Kontak />
      <CallToAction3 />
      <Footer />
    </div>
  );
};

export default App;
