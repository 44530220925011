import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useStateContext } from "./contexts/ContextProvider";
import YouTube from "react-youtube";
import BuatLaporanRibet from "./image/Pitch/Buat_Laporan_Ribet.png";
import CekDataDiluarKantor from "./image/Pitch/Cek_Data_Diluar_Kantor.png";
import KelolaBisnisRibet from "./image/Pitch/Kelola_Bisnis_Ribet.png";
import BisnisBerubahProgram from "./image/Pitch/Bisnis_Berubah_Program.png";

const PitchingSection = () => {
  const { screenSize } = useStateContext();
  const [isOpenYoutube, setIsOpenYoutube] = useState(false);
  const [videoActive, setVideoActive] = useState("");
  const [videoId, setVideoId] = useState("");
  const opts = {
    height: screenSize >= 930 ? "390" : "200",
    width: screenSize >= 930 ? "640" : "300",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: screenSize >= 930 ? "0px" : "20px",
      }}
    >
      <div
        className="text-center"
        style={{
          border: "1px solid orange",
          width: screenSize >= 930 ? "900px" : `${screenSize}px`,
          background: `linear-gradient(#fa9d6e, #eae41f)`,
        }}
      >
        <h2
          style={{
            color: "red",
            fontWeight: "800",
            marginTop: "20px",
            textShadow: "2px 2px 2px white",
          }}
        >
          PUNYA MASALAH BISNIS?
        </h2>
        {!isOpenYoutube ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: screenSize >= 930 ? "800px" : `${screenSize}px`,
                  flexDirection: screenSize >= 930 ? "row" : "column",
                }}
              >
                <div>
                  <img
                    src={BuatLaporanRibet}
                    alt="BuatLaporanRibet"
                    width={300}
                    onClick={() => {
                      setIsOpenYoutube(true);
                      setVideoActive("1");
                      setVideoId("x-R5yTGY-_g");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <p style={{ color: "red", fontWeight: "800" }}>
                    BUAT LAPORAN RIBET?
                  </p>
                </div>
                <div>
                  <img
                    src={CekDataDiluarKantor}
                    alt="CekDataDiluarKantor"
                    width={300}
                    onClick={() => {
                      setIsOpenYoutube(true);
                      setVideoActive("2");
                      setVideoId("jOcq1Jn9wGE");
                    }}
                    style={{
                      cursor: "pointer",
                      marginTop: screenSize >= 930 ? "0px" : "20px",
                    }}
                  />
                  <p style={{ color: "red", fontWeight: "800" }}>
                    CEK DATA DILUAR KANTOR?
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: screenSize >= 930 ? "800px" : `${screenSize}px`,
                  flexDirection: screenSize >= 930 ? "row" : "column",
                }}
              >
                <div>
                  <img
                    src={KelolaBisnisRibet}
                    alt="KelolaBisnisRibet"
                    width={300}
                    onClick={() => {
                      setIsOpenYoutube(true);
                      setVideoActive("3");
                      setVideoId("dz7uf80APqU");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <p style={{ color: "red", fontWeight: "800" }}>
                    KELOLA BISNIS RIBET?
                  </p>
                </div>
                <div>
                  <img
                    src={BisnisBerubahProgram}
                    alt="BisnisBerubahProgram"
                    width={300}
                    onClick={() => {
                      setIsOpenYoutube(true);
                      setVideoActive("4");
                      setVideoId("z1QHf_S-WQU");
                    }}
                    style={{
                      cursor: "pointer",
                      marginTop: screenSize >= 930 ? "0px" : "20px",
                    }}
                  />
                  <p style={{ color: "red", fontWeight: "800" }}>
                    BISNIS BERUBAH, PROGRAM?
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: screenSize >= 930 ? "row" : "column",
              }}
            >
              <YouTube videoId={videoId} opts={opts} />
              <div style={{ border: "3px solid blue", padding: "10px" }}>
                <div>
                  <p
                    style={{
                      color: "red",
                      fontWeight: "700",
                      fontSize: "15px",
                    }}
                  >
                    PUNYA MASALAH BISNIS?
                  </p>
                </div>
                {videoActive !== "1" && (
                  <div>
                    <img
                      src={BuatLaporanRibet}
                      alt="BuatLaporanRibet"
                      width={150}
                      onClick={() => {
                        setIsOpenYoutube(true);
                        setVideoActive("1");
                        setVideoId("x-R5yTGY-_g");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "800",
                        fontSize: "10px",
                      }}
                    >
                      BUAT LAPORAN RIBET?
                    </p>
                  </div>
                )}
                {videoActive !== "2" && (
                  <div>
                    <img
                      src={CekDataDiluarKantor}
                      alt="CekDataDiluarKantor"
                      width={150}
                      onClick={() => {
                        setIsOpenYoutube(true);
                        setVideoActive("2");
                        setVideoId("jOcq1Jn9wGE");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "800",
                        fontSize: "10px",
                      }}
                    >
                      CEK DATA DILUAR KANTOR?
                    </p>
                  </div>
                )}
                {videoActive !== "3" && (
                  <div>
                    <img
                      src={KelolaBisnisRibet}
                      alt="KelolaBisnisRibet"
                      width={150}
                      onClick={() => {
                        setIsOpenYoutube(true);
                        setVideoActive("3");
                        setVideoId("dz7uf80APqU");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "800",
                        fontSize: "10px",
                      }}
                    >
                      KELOLA BISNIS RIBET?
                    </p>
                  </div>
                )}
                {videoActive !== "4" && (
                  <div>
                    <img
                      src={BisnisBerubahProgram}
                      alt="BisnisBerubahProgram"
                      width={150}
                      onClick={() => {
                        setIsOpenYoutube(true);
                        setVideoActive("4");
                        setVideoId("z1QHf_S-WQU");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "800",
                        fontSize: "10px",
                      }}
                    >
                      BISNIS BERUBAH, PROGRAM?
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PitchingSection;
