import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useStateContext } from "./contexts/ContextProvider";
import CTA3 from "./image/CallToAction/Panggil_Programmer_Ivan_CTA_3.png";

const CallToAction3 = () => {
  const { screenSize } = useStateContext();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "40px",
      }}
    >
      <a href="https://api.whatsapp.com/send/?phone=%2B62895421397226&text&type=phone_number&app_absent=0">
        <img
          src={CTA3}
          alt="CTA3"
          style={{
            width: screenSize >= 930 ? "500px" : "300px",
          }}
        />
      </a>
    </div>
  );
};

export default CallToAction3;
