import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useStateContext } from "./contexts/ContextProvider";
import banner1 from "./image/Banner/Banner_Panggil_Programmer_Ivan_1.png";
import banner2 from "./image/Banner/Banner_Panggil_Programmer_Ivan_2.png";
import banner3 from "./image/Banner/Banner_Panggil_Programmer_Ivan_3.png";

const Banner = () => {
  const { screenSize } = useStateContext();

  return (
    <div className="jumbotron text-center text-white">
      {screenSize >= 930 ? (
        <a href="https://api.whatsapp.com/send/?phone=%2B62895421397226&text&type=phone_number&app_absent=0">
          <img src={banner3} alt="banner3" width={900} />
        </a>
      ) : (
        <>
          <a href="https://api.whatsapp.com/send/?phone=%2B62895421397226&text&type=phone_number&app_absent=0">
            <img src={banner2} alt="banner2" width={300} />
          </a>
        </>
      )}
    </div>
  );
};

export default Banner;
